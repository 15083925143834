<div class="min-h-screen flex flex-col">
  <app-header
    [companyLogo]="companyInfo?.logoUrl"
    [languages]="marketAppSettings?.languages"
    [menuCollections]="menuCollections"
  />
  <main class="pt-44 flex-grow">
    <router-outlet />
  </main>
  <app-footer
    id="website-footer"
    class="mt-5 opacity-0 transition-opacity duration-500 ease-in-out"
    [companyInfo]="companyInfo"
  />
</div>
<ngx-loading
  [show]="loader"
  [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true, }"
></ngx-loading>

<app-modal [modalId]="'storesModal'" [isOpen]="isModalOpen" (reset)="resetModal()">
  <app-stores-address-modal *ngIf="isStoresModalOpen" (closeModal)="closeStoresModal($event)" />
</app-modal>

<app-drawer
  [drawerId]="'myAccountDrawer'"
  [backdrop]="false"
  [isOpen]="isDrawerOpen"
  (reset)="reset()"
>
  <app-confirm-user-details
    *ngIf="isProfileOpen"
    [userDetails]="user"
    (closeDrawer)="closeUserDetailDrawer($event)"
    (onSubmit)="onConfirm($event)"
  />
</app-drawer>

<div
  drawer-backdrop=""
  id="confirm-backrop"
  class="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30 modal-backdrop-color __web-inspector-hide-shortcut__"
></div>
