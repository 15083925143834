import { MetaService } from './shared/services/meta.service';
import { ScriptsService } from './shared/services/scripts.service';
import { Component, inject, Renderer2, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { EddressFacadeService } from './core/services/eddressFacade.service';
import { LoaderService } from './core/services/loader.service';
import { GenericService } from './core/services/generics.service';
import { AuthService } from './core/services/auth.service';
import { environment } from '@environments/environment';
import { MyAccountService } from './user-information/my-account/services/my-account.service';
import { Title } from '@angular/platform-browser';
import { TitleService } from './core/services/titleService.service';
import { ConfirmUserDetailsComponent } from './user-information/my-account/components/confirm-user-details/confirm-user-details.component';
import { DrawerComponent } from './shared/drawer/drawer.component';
import { StoresAddressModalComponent } from './shared/components/stores-address-modal/stores-address-modal.component';
import { NgIf } from '@angular/common';
import { ModalComponent } from './shared/components/modal/modal.component';
import { NgxLoadingModule } from 'ngx-loading';
import { FooterComponent } from './layout/footer/footer.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { LocalStorageService } from './core/services/localstorage.service';
import { select, Store } from '@ngrx/store';
import { AppState } from './app.state';
import { addToPageTitles } from './store/actions/pageTitles.action';
import { Observable } from 'rxjs/internal/Observable';
import { selectDataLoaded } from './store/selectors/pageTitles.selector';

import { AppInitService } from './core/services/app-init.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    NgxLoadingModule,
    ModalComponent,
    NgIf,
    StoresAddressModalComponent,
    DrawerComponent,
    ConfirmUserDetailsComponent,
  ],
})
export class AppComponent implements OnInit {
  @ViewChild(HeaderComponent, { static: false })
  headerComponent: HeaderComponent;
  title = 'web-app';

  loader = false;
  isModalOpen: boolean = false;
  isStoresModalOpen: boolean = false;
  isDrawerOpen: boolean = false;
  isProfileOpen: boolean = false;

  menuCollections = [];
  defaultLocations = [];
  selectedStore;
  stores = [];

  deliveryAddress;
  marketAppSettings;
  companyInfo;
  scripts;
  user;
  isPageTitlesLoaded$: Observable<boolean>;
  private appInitService = inject(AppInitService);
  constructor(
    public eddress: EddressFacadeService,
    private loaderService: LoaderService,
    private genericService: GenericService,
    private authService: AuthService,
    private metaService: MetaService,
    private scriptsService: ScriptsService,
    private renderer: Renderer2,
    private myAccountService: MyAccountService,
    private titleService: Title,
    private titleServiceService: TitleService,
    private localStorage: LocalStorageService,
    private store: Store<AppState>,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.companyInfo = this.appInitService.companyInfo;
    this.localStorage.setItem('termsAndConditionsUrl', this.companyInfo?.termsAndConditionsUrl);

    this.marketAppSettings = this.appInitService.marketAppSettings;
    this.genericService.setMarketAppSetting(this.marketAppSettings);
    this.marketAppSettings.languages = this.marketAppSettings?.languages.filter(
      (l) => l.name === 'English'
    );
    this.getScripts();
    this.isPageTitlesLoaded$ = this.store.pipe(select(selectDataLoaded));
    this.isPageTitlesLoaded$.subscribe((isLoaded) => {
      if (!isLoaded) {
        // this.fetchPagesDetail();
      }
    });

    this.eddress.setAppFavicon(environment.eddressAppConfig.favIcon);
    this.titleService.setTitle(environment.eddressAppConfig.companyTitle);

    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
        if (!this.user.phoneNumber) {
          setTimeout(() => {
            const backdrop = document.getElementById('confirm-backrop');
            backdrop.style.display = 'inline';
            this.openDrawer();
          }, 1000);
        }
        this.checkCrediablity();
      }
    });
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
    this.eddress.populateDeliveryAddress();
    this.eddress.populateUser();
    this.eddress.populateSelectedStore();
    this.eddress.populateHomePageData();
    this.genericService.populateCompanyConfigurations();
    this.loaderService.getLoader().subscribe((res) => {
      this.loader = res;
    });
  }
  ngOnInit(): void {
    initFlowbite();
    this.titleServiceService.init();
    if (!this.user) {
      this.getPublicSotres();
    }
    this.genericService.checkCanonicalTag();
    setTimeout(() => {
      const footerElement = document.getElementById('website-footer');
      footerElement.classList.remove('opacity-0');
      footerElement.classList.add('opacity-100');
    }, 2000);
  }
  ngAfterViewInit(): void {
    // Ensure initFlowbite is called after the view is initialized
    this.initializeFlowbite();
  }

  initializeFlowbite() {
    setTimeout(() => {
      if (typeof initFlowbite === 'function') {
        initFlowbite();
      }
    }, 100); // Delay for debugging; adjust or remove as needed
  }

  getScripts() {
    if (this.marketAppSettings) {
      const params = {
        tenantUid: this.marketAppSettings?.tenantUid,
      };
      this.scriptsService.getScripts(params).subscribe((data) => {
        this.scripts = data;

        this.scripts.forEach((s) => {
          // Regular expression to match all <script> tags
          const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;

          // Regular expression to match all <noscript> tags
          const noscriptRegex = /<noscript\b[^>]*>([\s\S]*?)<\/noscript>/gi;

          let match;

          // Process all <script> tags
          while ((match = scriptRegex.exec(s.script)) !== null) {
            const scriptAttributes = match[0]; // Attributes in the <script> tag (e.g., src="...")
            const scriptContent = match[1]; // Content inside <script>...</script>

            // Create a script element
            const scriptElement = document.createElement('script');

            // Check if the <script> tag contains the src attribute
            const srcRegex = /src=["']([^"']+)["']/;
            const srcMatch = srcRegex.exec(scriptAttributes);

            // Check if the script is an external one (contains "src")
            if (srcMatch) {
              scriptElement.src = srcMatch[1]; // Set the src attribute for external scripts
              scriptElement.async = true;
            } else {
              // If the script is inline, set its content as innerHTML
              scriptElement.innerHTML = scriptContent;
            }

            // Append the script element to the head tag
            if (s.scriptPlacement === 'HEADER') {
              this.renderer.appendChild(document.head, scriptElement);
            } else if (s.scriptPlacement === 'FOOTER') {
              this.renderer.appendChild(document.body, scriptElement);
            }
          }

          // Process all <noscript> tags
          while ((match = noscriptRegex.exec(s.script)) !== null) {
            const noscriptContent = match[1]; // Content inside <noscript>...</noscript>

            // Create a noscript element
            const noscriptElement = document.createElement('noscript');
            noscriptElement.innerHTML = noscriptContent;

            // Append the noscript element to the head tag
            if (s.scriptPlacement === 'HEADER') {
              this.renderer.appendChild(document.head, noscriptElement);
            } else if (s.scriptPlacement === 'FOOTER') {
              const body = document.body;
              this.renderer.insertBefore(body, noscriptElement, body.firstChild);
            }
          }
        });
      });
    }
  }

  // Async method to fetch scripts
  async fetchPagesDetail(): Promise<void> {
    try {
      const data = await this.genericService.getPagesDetailAsPromise(
        this.marketAppSettings?.tenantUid
      );
      this.store.dispatch(addToPageTitles({ pageData: data, isLoaded: true }));
    } catch (error) {
      console.error('Error fetching scripts', error);
    }
  }

  // public api's
  getPublicSotres() {
    const params = {
      isLazy: true,
      locale: 'en',
      os: 'web',
      appName: environment.eddressAppConfig.appName,
      version: '1.0',
      isDeliveryStore: true,
    };
    this.loaderService.open();
    this.genericService.getPublicStores(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.stores = result.stores;
        this.selectedStore = result.stores[0];
        if (this.selectedStore) {
          this.selectedStore.tenantUid = this.marketAppSettings?.tenantUid;
        }
        this.genericService.setConfigurations({
          tenantCurrencySymbol: this.selectedStore?.currency?.symbol,
          tenantUid: this.marketAppSettings?.tenantUid,
        });
        this.genericService.setCompanyConfigurations({
          tenantCurrencySymbol: this.selectedStore?.currency?.symbol,
          tenantUid: this.marketAppSettings?.tenantUid,
        });
        if (this.selectedStore) this.authService.setUserSelectedStore(this.selectedStore);
        this.genericService.setDefaultLocations([
          {
            country: this.selectedStore?.country?.name,
            label: this.selectedStore?.label,
            lat: this.selectedStore?.lat,
            lon: this.selectedStore?.lon,
          },
        ]);
        this.getPublicHomePageData();
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }
  getPublicHomePageData() {
    const params = {
      locale: 'en',
      os: 'web',
      locality: {
        coordinates: {
          lat: this.selectedStore?.lat,
          lon: this.selectedStore?.lon,
        },
      },
      operationUid: environment.eddressAppConfig.operationUid,
      appName: environment.eddressAppConfig.appName,
    };
    this.loaderService.open();
    this.genericService.getPublicHomepages(this.selectedStore?.id, params).subscribe(
      (result) => {
        this.setHomePageResult(result);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  checkCrediablity() {
    const params = {
      appVersionCode: 1,
      operationUid: environment.eddressAppConfig.operationUid,
      os: 'web',
      pushToken: environment.eddressAppConfig.pushToken, //
      pushTokenProjectId: environment.eddressAppConfig.pushTokenProjectId,
      locale: 'en',
    };
    this.loaderService.open();
    this.genericService.checkCredibility(params).subscribe(
      (result) => {
        this.loaderService.close();
        result.tenantUid = this.marketAppSettings?.tenantUid;
        this.genericService.setConfigurations(result);
        this.genericService.setCompanyConfigurations(result);
        this.defaultLocations = result.defaultLocations;
        this.genericService.setDefaultLocations(result.defaultLocations);
        this.getSotres(this.user);
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }

  getSotres(user) {
    const params = {
      isLazy: true,
      locale: 'en',
      locality: {
        coordinates: {
          lat: this.deliveryAddress
            ? this.deliveryAddress?.coordinates?.lat
            : this.defaultLocations[0].lat,
          lon: this.deliveryAddress
            ? this.deliveryAddress?.coordinates?.lon
            : this.defaultLocations[0].lon,
        },
      },
      os: 'web',
      uid: user.uid,
      idUser: user.idUser,
      appName: environment.eddressAppConfig.appName,
      version: '1.0',
      isDeliveryStore: true,
    };
    this.loaderService.open();
    this.genericService.getStores(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.stores = result.stores;
        this.selectedStore = result.stores[0];
        if (this.selectedStore) {
          this.selectedStore.tenantUid = this.marketAppSettings?.tenantUid;
          this.selectedStore.predefinedNotes = result.predefinedNotes;
        }
        if (this.selectedStore) this.authService.setUserSelectedStore(this.selectedStore);
        this.getHomePageData();
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }
  getHomePageData() {
    const params = {
      locale: 'en',
      os: 'web',
      locality: {
        coordinates: {
          lat: this.deliveryAddress
            ? this.deliveryAddress?.coordinates?.lat
            : this.defaultLocations[0].lat,
          lon: this.deliveryAddress
            ? this.deliveryAddress?.coordinates?.lon
            : this.defaultLocations[0].lon,
        },
      },
      uid: this.user.uid,
      idUser: this.user.idUser,
      operationUid: environment.eddressAppConfig.operationUid,
      appName: environment.eddressAppConfig.appName,
      appIdentifier: environment.firebaseConfig.appId,
    };
    this.loaderService.open();
    this.genericService.getHomepages(this.selectedStore?.id, params).subscribe(
      (result) => {
        this.setHomePageResult(result);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  setHomePageResult(result) {
    this.loaderService.close();
    if (result) {
      const homeSections = result.homeSections;
      const collections = [];
      homeSections.forEach((section) => {
        section.items.forEach((item) => {
          if (item.type === 'COLLECTION') {
            collections.push(item);
          }
        });
      });
      const collectionGroups = [];
      for (let [_, value] of Object.entries(result.collectionGroups)) {
        collectionGroups.push(value);
      }
      collections.forEach((collection) => {
        collection['groups'] = collectionGroups.filter((c) => c.idCollection === collection.itemId);
      });
      this.menuCollections = collections;

      if (result.metaKeyword) {
        this.metaService.setMetaTag(result.metaKeyword);
      }
    }
    this.authService.setHomePageData(result);
  }

  resetModal() {
    this.isModalOpen = false;
  }
  closeStoresModal($event) {
    this.isModalOpen = false;
    this.isStoresModalOpen = $event;
  }
  openModal() {
    this.isModalOpen = true;
    this.isStoresModalOpen = true;
  }
  reset() {
    this.isDrawerOpen = false;
  }
  closeUserDetailDrawer($event) {
    this.isDrawerOpen = false;
    this.isProfileOpen = $event;
  }
  openDrawer() {
    this.isDrawerOpen = true;
    this.isProfileOpen = true;
  }
  onConfirm(form) {
    this.loaderService.open();
    this.myAccountService.saveUserAccountInformation(form).subscribe(
      (result) => {
        this.loaderService.close();
        this.reset();
        this.getUserAccountInfo();
        this.headerComponent.openDrawer('addresses');
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  getUserAccountInfo() {
    this.loaderService.open();
    this.myAccountService.getUserAccountInformation().subscribe(
      (result) => {
        const backdrop = document.getElementById('confirm-backrop');
        backdrop.style.display = 'none';
        // this.userInfo = result;
        this.user.phoneNumber = result.phoneNumber;
        this.authService.setUserAuthentication(this.user);
        // window.location.reload();
        this.loaderService.close();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  ngAfterContentInit() {
    const backdrop = document.getElementById('confirm-backrop');
    backdrop.style.display = 'none';
  }
}
