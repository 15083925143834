// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  eddressAppConfig: {
    apiBaseUrl: 'https://prod-api.eddress.co/',
    favIcon: 'assets/images/companies-favIcons/yolla-favicon.png',
    companyTitle: 'Yolla Website',
    homePageTitle: 'Online Grocery Shopping Made Easy',
    appName: 'yolla',
    operationUid: 'yolla',
    countryCode: '+49',
    pushToken:
      'BA9MuIuuQGj4aX6PUt8DPp6lY1DuuHUWqPE4jR7cgC5Ooc-5-HUotVx0E3ftQHOqKUsBqojCyuTfDSF_PZxPYBI',
    pushTokenProjectId: 'goyolla-47a47',
    stripePublicKey:
      'pk_test_51LojQzAioBvl7Vd8Mgx7GgYMqdt2x0sE7O2M5QrSCjXROn7NnSvSLZzLlx4FOEEX9smd0nGlAhlELo3cyfrpLcFZ00rbJZS8hG',
    appUrls: {
      ios: 'https://apps.apple.com/pk/app/yolla-halal-supermarkt/id1634377704',
      android: 'https://play.google.com/store/apps/details?id=com.goyolla&pli=1',
    },
    paymentGateway: 'ADYENDROPIN',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyB_N_VrDYH8mU0NmDgjCnG6T0PXnckbwZA',
    authDomain: 'goyolla-47a47.firebaseapp.com',
    projectId: 'goyolla-47a47',
    storageBucket: 'goyolla-47a47.appspot.com',
    messagingSenderId: '506064767022',
    appId: '1:506064767022:web:52178e5fcf46dc9b64b7bf',
    measurementId: 'G-2BW3J7C8BN',
  },
  recaptcha: {
    siteKey: '6Lfi4XcqAAAAAOqVKLtzopHdOhhG-r9hcLd00wyf',
  },
};
